// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "@rails/request.js"
import "./jquery"
import * as bootstrap from "bootstrap"
import "js-cookie"
import "@progress/kendo-ui/js/kendo.dialog"
// import "@progress/kendo-ui/js/kendo.dropdownlist"
import "@progress/kendo-ui/js/kendo.grid"
import "@progress/kendo-ui/js/kendo.numerictextbox"
// import "@progress/kendo-ui/js/kendo.radiogroup"
import "@progress/kendo-ui/js/kendo.tooltip"
// import "@progress/kendo-ui/js/kendo.all"
// import "./ckeditor/config"
import "./controllers"
